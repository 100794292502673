.InclusionCard {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.InclusionCard svg {
  width: 1.5rem;
  height: 1.5rem;
}

.InclusionCard:not(:last-child) {
  margin-bottom: 2rem;
}

.InclusionCard .ImageUrl {
  width: 70px;
  height: 70px;
  position: relative;
  padding-left: 5px;
}

.InclusionCard .ImageUrl > img {
  width: 100%;
  height: 100%;
}

.InclusionCard .ImageUrl > svg {
  position: absolute;
  top: -22px;
  right: -5px;
  cursor: pointer;
}

.InclusionCard .ImageUrl > svg:hover {
  opacity: 0.7;
}

.InclusionCard .button {
  display: flex;
  gap: 10px;
}

.InclusionCard .button > button {
  background-color: #c8a063;
  font-size: 12px;
}
